// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,\nhtml {\n  margin: 0;\n  padding: 0;\n  min-height: 100%;\n  height: 100%;\n}\nbody > div,\nhtml > div {\n  min-height: 100%;\n  height: 100%;\n}\n\n.app_app__OsQoD {\n  min-height: 100%;\n  height: 100%;\n}\n.app_app__OsQoD main {\n  height: 100%;\n  margin: 0 auto;\n}", "",{"version":3,"sources":["webpack://./src/app.module.scss"],"names":[],"mappings":"AAAA;;EAEE,SAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;AACF;AACE;;EACE,gBAAA;EACA,YAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;AACJ","sourcesContent":["body,\nhtml {\n  margin: 0;\n  padding: 0;\n  min-height: 100%;\n  height: 100%;\n\n  > div {\n    min-height: 100%;\n    height: 100%;\n  }\n}\n\n.app {\n  min-height: 100%;\n  height: 100%;\n\n  main {\n    height: 100%;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "app_app__OsQoD"
};
export default ___CSS_LOADER_EXPORT___;
